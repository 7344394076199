import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { FullScreenLoader } from '@savgroup-front-common/core/src/molecules/FullScreenLoader';

import { useInitializeDatadog } from '../../../../datadog.config';
import { useClientInformationContext } from '../../atoms';
import { MyAccountLoginPageNewDesign } from '../../pages/MyAccountLoginPage/MyAccountLoginPageNewDesign';

import Callback from './Callback';

export const MyAccountRoutes: React.FC = () => {
  const { clientInformation, sellers, isLoading } =
    useClientInformationContext();

  useInitializeDatadog();

  if (!clientInformation || !sellers.length || isLoading) {
    return <FullScreenLoader isOpen={isLoading} />;
  }

  return (
    <Routes>
      <Route path="/callback" Component={() => <Callback />} />

      <Route path="/" element={<MyAccountLoginPageNewDesign />} />

      <Route path="/new_design" element={<MyAccountLoginPageNewDesign />} />
    </Routes>
  );
};
