import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { toast } from '@savgroup-front-common/core/src/atoms/Toast';
import { SellerService } from 'authenticator/api';

import messages from '../messages';

const useSalesLeadConfirmEmailCommand = () => {
  const { requestId, requestToken } = useParams<{
    requestId: string;
    requestToken: string;
  }>();

  const { data, isLoading } = useQuery(
    ['getSalesLeadConfirmEmail', { requestId, requestToken }],
    async () => {
      if (!requestId || !requestToken) {
        return undefined;
      }

      const response = await SellerService.salesLeadConfirmEmailCommand({
        id: requestId,
        token: requestToken,
      });

      if (response.failure) {
        toast.error(messages.salesLeadConfirmEmailCommandError);

        return response.failure;
      }

      return response.failure;
    },
    {
      staleTime: Infinity,
    },
  );

  return {
    isFailed: data || false,
    isLoading,
  };
};

export default useSalesLeadConfirmEmailCommand;
