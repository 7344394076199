import React, { useCallback, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import OnboardingActivities from 'authenticator/view/pages/Onboarding/OnboardingActivities/OnboardingActivities';
import OnboardingConfirmation from 'authenticator/view/pages/Onboarding/OnboardingConfirmation/OnboardingConfirmation';
import OnboardingEntry from 'authenticator/view/pages/Onboarding/OnboardingEntry/OnboardingEntry';
import OnboardingSetUp from 'authenticator/view/pages/Onboarding/OnboardingSetUp/OnboardingSetUp';

import { useInitializeDatadog } from '../../../../datadog.config';

export const OnboardingRoutes: React.FC = () => {
  const [userEmail, setUserEmail] = useState('');

  const handleUserEmail = useCallback((userEmail: string) => {
    setUserEmail(userEmail);
  }, []);

  useInitializeDatadog({ ratio: 100 });

  return (
    <Routes>
      <Route
        path="/onboarding"
        element={<OnboardingEntry handleUserEmail={handleUserEmail} />}
      />
      <Route path="/onboarding/:emailId" element={<OnboardingActivities />} />
      <Route
        path="/onboarding/:emailId/confirmation"
        element={<OnboardingConfirmation userEmail={userEmail} />}
      />
      <Route
        path="/confirm/:requestId/:requestToken"
        element={<OnboardingSetUp />}
      />

      <Route path="*" element={<Navigate to="/onboarding" />} />
    </Routes>
  );
};

OnboardingRoutes.displayName = 'OnboardingRoutes';
