import { APP_ENVS } from '@savgroup-front-common/constants';
import { AUTHENTICATOR_COMPONENT } from 'authenticator/types/AuthenticatorComponents';

const onboardingEnvs: Record<APP_ENVS, string> = {
  DEV: 'dev-onboarding.revers.io',
  DEMO: 'demo-onboarding.revers.io',
  PROD: 'onboarding.revers.io',
  PREPROD: 'preprod-onboarding.revers.io',
  DEFAULT: '',
  LOCAL: '',
  TEST: '',
};

const useIsOnboardingEnabled = (): boolean => {
  const { hostname } = window.location;

  if (
    import.meta.env.VITE_AUTHENTICATOR_COMPONENT ===
    AUTHENTICATOR_COMPONENT.ON_BOARDING
  ) {
    return true;
  }

  if (onboardingEnvs[import.meta.env.VITE_ENV as APP_ENVS] === hostname) {
    return true;
  }

  return false;
};

export default useIsOnboardingEnabled;
