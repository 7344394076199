import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { SPECIFIC_ERROR_CODES } from '@savgroup-front-common/constants';
import { toast } from '@savgroup-front-common/core/src/atoms/Toast';
import { selectLocale } from '@savgroup-front-common/core/src/domains/i18n/selectors';
import { SellerService } from 'authenticator/api';

import messages from '../messages';

import { OnboardingFormFieldValues } from './useOnboardingFormContext';

const useUpsertSalesLeadCommand = () => {
  const navigate = useNavigate();
  const locale = useSelector(selectLocale);

  const { mutateAsync: handleSubmitSalesLead, isLoading } = useMutation(
    [],
    async (data: OnboardingFormFieldValues) => {
      const response = await SellerService.upsertSalesLeadCommand({
        companyName: data.companyName,
        email: data.email,
        firstName: data.firstName,
        cName: data.cName,
        lastName: data.lastName,
        phone: data.phone,
        language: locale,
      });

      if (
        response.errors?.at(0)?.code ===
        SPECIFIC_ERROR_CODES.INVALID_EMAIL_ADDRESS
      ) {
        toast.error(messages.invalidEmail);

        return undefined;
      }

      if (response.failure) {
        toast.error(messages.accoundCreationError);

        return undefined;
      }

      navigate(`/onboarding/${response.value}`);

      return undefined;
    },
  );

  return { handleSubmitSalesLead, isLoading };
};

export default useUpsertSalesLeadCommand;
