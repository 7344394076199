import React from 'react';
import { Navigate } from 'react-router-dom';

import { useSearchParams } from '@savgroup-front-common/core/src/hooks';

const Callback = () => {
  const query = useSearchParams();
  const redirectUri = query.get('redirectUri') || '';

  return <Navigate to={redirectUri} />;
};

export default Callback;
